import React, { useState, useEffect } from 'react';
import Moment from 'moment';
import Modal from './Modal/ProcessListModal';
import NewAutomationModal from './Modal/NewAutomationModal';
import { BsCardList } from 'react-icons/bs';
import { AiOutlinePlusSquare } from 'react-icons/ai';

function BodyProcessList ({ content, processList, getProcessList, eta }) {
  const [show, setShow] = useState(false);
  const [ShowNewAutomation, setShowNewAutomation] = useState(false);
  const [RemainingTime, setRemainingTime] = useState('');
  const date = Moment(content.created_at).format('M/D/YY hh:mma');
  let endTime;

  const getEta = () => {
    eta--;
    endTime = Moment().second(eta);
    console.log('end time:', endTime);
    console.log('time remaining', Moment(endTime).fromNow());
    setRemainingTime(Moment(endTime).fromNow());
    if (eta > 0) setTimeout(getEta, 1000);
  };

  useEffect(() => {
    getEta();
  }, [eta]);

  return (
    <div className='mb-top'>
      <Modal
        onClose={() => {
          return setShow(false);
        }}
        show={show}
        processList={processList}
      />
      <NewAutomationModal
        onClose={() => {
          console.log('close setShowNewAutomation');
          return setShowNewAutomation(false);
        }}
        show={ShowNewAutomation}
      />
      <div className='stats'>
        <div className='stats_container'>
          <div className='stats_headerbox'>
            <div className='stats_container-title'>
              <h2>
                <u>Job Statistics</u>
              </h2>
            </div>
            <div className='stats_button-container'>
              <button
                className='btnProcessList btn cursorPointer'
                onClick={() => {
                  return setShowNewAutomation(true);
                }}
              >
                <span className='btn_icon'>
                  <AiOutlinePlusSquare />
                </span>{' '}
                Create New Automation Job
              </button>
              &nbsp;
              <button
                className='btnProcessList btn cursorPointer'
                onClick={() => {
                  getProcessList();
                  return setShow(true);
                }}
              >
                <span className='btn_icon'>
                  <BsCardList />
                </span>{' '}
                Track other Automation Jobs
              </button>
            </div>
          </div>
          <div className='stats_tblbox'>
            <div>
              <table>
                <thead></thead>
                <tbody>
                  <tr>
                    <td>Started at: </td>
                    <td>{content.process_id !== undefined && date}</td>
                  </tr>
                  <tr>
                    <td>Started by: </td>
                    <td>{content.email}</td>
                  </tr>
                  <tr>
                    <td>Number of Websites: </td>
                    <td>{content.no_data}</td>
                  </tr>
                  <tr>
                    <td>Remaining total est time: </td>
                    {content.process_id !== undefined && (content.step !== 7 ? <td>{!RemainingTime ? 'N/A' : RemainingTime}</td> : <td>Finished</td>)}
                  </tr>
                </tbody>
              </table>
            </div>
            <div>
              <table>
                <thead></thead>
                <tbody>
                  <tr>
                    <td>Job Id:</td>
                    <td>{content.process_id}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BodyProcessList;
