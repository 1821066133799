import React from 'react';

const Login = ({ checkValidation }) => {
  const callEsoLogin = () => {
    window.open(`https://login.microsoftonline.com/${process.env.REACT_APP_ESO_TENANT_ID}/oauth2/v2.0/authorize?client_id=${process.env.REACT_APP_ESO_CLIENT_ID}&response_type=id_token&scope=openid+email+profile&response_mode=fragment&state=12345&nonce=678910`, 'Popup', 'width=500,height=700');
    const onMessage = (event) => {
      if (event.data.type !== 'auth') return;
      localStorage.setItem('esoToken', event.data.body.id_token);
      window.removeEventListener('message', onMessage);
      fetch(process.env.REACT_APP_API_ESO_AUTH_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${event.data.body.id_token}`
        },
        body: JSON.stringify({ ping: 'cold start' })
      }).then((res) => {
        return res.json();
      }).then((res) => {
        localStorage.setItem('appToken', res.appToken);
        checkValidation();
      });
    };
    window.addEventListener('message', onMessage);
  };

  return (
    <button className='login-button' onClick={() => { return callEsoLogin(); }}>
      Login with Accenture
    </button>
  );
};

export default Login;
