import React from 'react';

const Pagination = ({ prListPerPage, totalPrList, paginate }) => {
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(totalPrList / prListPerPage); i++) {
    pageNumbers.push(i);
  }
  return (
    <>
      <nav>
        <ul className='pagination'>
          {pageNumbers.map((number) => {
            return (
            <li key={number} className='page-item'>
              <button onClick={() => { return paginate(number); }} href='!#' className='page-link'>
                {number}
              </button>
            </li>
            );
          })}
        </ul>
      </nav>
    </>
  );
};

export default Pagination;
