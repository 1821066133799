import React from 'react';

export function Step12 ({ dataPreEnrichment }) {
  return (
    <>
      <h2 className='body-bottom-header'>Step 13 - Tracker</h2>
      <h4 className='body-bottom-subheader'>Create trackers for important parts of the process.</h4>
    </>
  );
}
