import React from 'react';
import Cards from './Helper/Cards';
import Moment from 'moment';

function arrow (status) {
  if (status === 'COMPLETED') {
    return (
      <div className='card__container-arrow'>
        <div className='card__container-arrow-body'></div>
        <div className='card__container-arrow-head'></div>
      </div>
    );
  }
  return (
    <div className='card__container-arrow'>
      <div className='card__container-arrow-body-2'></div>
      <div className='card__container-arrow-head-2'></div>
    </div>
  );
}

function secondsToHms (seconds) {
  return Moment.duration(seconds, 'seconds').format();
}

function BodyStatusSteps ({ content, arrEstimatedSecondsPerStep, newEta }) {
  let crawlerStatus = null;
  if (content.all_attribute === 'COMPLETED' && content.chatbot_api === 'COMPLETED' && content.chatbot_keywords === 'COMPLETED' && content.tiktok === 'COMPLETED' && content.covid === 'COMPLETED' && content.hqi === 'COMPLETED') {
    crawlerStatus = 'COMPLETED';
  } else if (content.all_attribute === 'STARTING' && content.chatbot_api === 'STARTING' && content.chatbot_keywords === 'STARTING' && content.tiktok === 'STARTING' && content.covid === 'STARTING' && content.hqi === 'STARTING') {
    crawlerStatus = 'STARTING';
  } else {
    crawlerStatus = null;
  }
  return (
    <div className='mb-middle'>
      <div className='main-container'>
        <div className='card-container'>
          <Cards
            status={content.import}
            title='Step 1'
            description='Format Data'
            url={content.url}
            date={content.import_timestamp}
            processId={content.process_id}
            campaignId={content.campaign_id}
            emailTimestamp={content.folder_path}
            step="import"
            estimatedTime={'N/A'}
          />
          {arrow(content.import)}
          <Cards
            status={content.clean_import}
            title='Step 2'
            description='Clean Data'
            step="clean-import"
            date={content.clean_import_timestamp}
            processId={content.process_id}
            emailTimestamp={content.folder_path}
            estimatedTime={secondsToHms(arrEstimatedSecondsPerStep[1] - arrEstimatedSecondsPerStep[0])}
          />
          {arrow(content.clean_import)}
          <Cards
            status={content.identify_domain}
            title='Step 3'
            description='Identify Domain'
            step="identify-domain"
            date={content.identify_domain_timestamp}
            processId={content.process_id}
            emailTimestamp={content.folder_path}
            estimatedTime={secondsToHms(arrEstimatedSecondsPerStep[2] - arrEstimatedSecondsPerStep[1])}
          />
          {arrow(content.identify_domain)}
          <Cards
            status={content.fetch_private_domain}
            title='Step 4'
            description='Fetch Private Domain'
            step="fetch-private-domain"
            processId={content.process_id}
            date={content.fetch_private_domain_timestamp}
            emailTimestamp={content.folder_path}
            estimatedTime={secondsToHms(arrEstimatedSecondsPerStep[3] - arrEstimatedSecondsPerStep[2])}
          />
          {arrow(content.fetch_private_domain)}
          <Cards
            status={content.company_name_dq}
            title='Step 5'
            processId={content.process_id}
            description='Company Name DQ'
            step="company-name-dq"
            date={content.company_name_dq_timestamp}
            emailTimestamp={content.folder_path}
            estimatedTime={secondsToHms(arrEstimatedSecondsPerStep[4] - arrEstimatedSecondsPerStep[3])}
          />
          {arrow(content.company_name_dq)}
          <Cards
            status={content.fetch_public_domain}
            title='Step 6'
            processId={content.process_id}
            description='Fetch Public Domain'
            step="fetch-public-domain"
            date={content.fetch_public_domain_timestamp}
            emailTimestamp={content.folder_path}
            estimatedTime={secondsToHms(arrEstimatedSecondsPerStep[4] - arrEstimatedSecondsPerStep[3])}
          />
          {arrow(content.fetch_public_domain)}
          <Cards
            status={content.fuzzy_match_domain}
            processId={content.process_id}
            title='Step 7'
            description=' Fuzzy Matching'
            step="fuzzy-match-domain"
            date={content.fuzzy_match_domain_timestamp}
            emailTimestamp={content.folder_path}
            estimatedTime={secondsToHms(arrEstimatedSecondsPerStep[5] - arrEstimatedSecondsPerStep[4])}
          />
          {arrow(content.fuzzy_match_domain)}
          <Cards
            status={content.clean_domain}
            processId={content.process_id}
            title='Step 8'
            description='Clean Domain'
            step="clean-domain"
            url={content.url}
            campaignId={content.campaign_id}
            date={content.clean_domain_timestamp}
            emailTimestamp={content.folder_path}
            estimatedTime={secondsToHms(arrEstimatedSecondsPerStep[6] - arrEstimatedSecondsPerStep[5])}
          />
          {arrow(content.clean_domain)}
          <Cards
            status={crawlerStatus}
            title='Step 9'
            description='Run Crawlers'
            url={content.url}
            campaignId={content.campaign_id}
            processId={content.process_id}
            date={content.step_7_timestamp}
            emailTimestamp={content.folder_path}
            estimatedTime={secondsToHms(arrEstimatedSecondsPerStep[6] - arrEstimatedSecondsPerStep[5])}
          />
          {arrow(crawlerStatus)}
          <Cards
            status={content.create_report}
            processId={content.process_id}
            title='Step 10'
            description='Create Leads Aggregate Report'
            step="create-report"
            url={content.url}
            campaignId={content.campaign_id}
            date={content.step_7_timestamp}
            emailTimestamp={content.folder_path}
            estimatedTime={secondsToHms(arrEstimatedSecondsPerStep[6] - arrEstimatedSecondsPerStep[5])}
          />
          {arrow(content.create_report)}
          <Cards
            status={content.create_scoring}
            title='Step 11'
            description='Model Scoring'
            processId={content.process_id}
            step="create-scoring"
            url={content.url}
            campaignId={content.campaign_id}
            date={content.step_7_timestamp}
            emailTimestamp={content.folder_path}
            estimatedTime={secondsToHms(arrEstimatedSecondsPerStep[6] - arrEstimatedSecondsPerStep[5])}
          />
          {arrow(content.create_scoring)}
          <Cards
            status={content.final_output}
            title='Step 12'
            processId={content.process_id}
            description='Arrange Result Output'
            url={content.url}
            step="final-output"
            campaignId={content.campaign_id}
            date={content.step_7_timestamp}
            emailTimestamp={content.folder_path}
            estimatedTime={secondsToHms(arrEstimatedSecondsPerStep[6] - arrEstimatedSecondsPerStep[5])}
          />
          {arrow(content.final_output)}
          <Cards
            status={content.final_output}
            title='Step 13'
            processId={content.process_id}
            description='Tracker'
            url={content.url}
            step="trackers"
            campaignId={content.campaign_id}
            date={content.tracker_timestamp}
            emailTimestamp={content.folder_path}
            estimatedTime={secondsToHms(arrEstimatedSecondsPerStep[6] - arrEstimatedSecondsPerStep[5])}
          />
        </div>
      </div>
    </div>
  );
}

export default BodyStatusSteps;
