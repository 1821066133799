import React from 'react';
import { SiPivotaltracker } from 'react-icons/si';
import { BiUser } from 'react-icons/bi';
import { useJwt } from 'react-jwt';
// import OptionsModal from './Modal/OptionsModal';

function Header () {
  const { decodedToken } = useJwt(localStorage.getItem('appToken'));
  console.log('decodedToken', decodedToken);
  return (
    <div className='header'>
      <div className='cont-h'>
        <p>
          <span className='btn_icon'>
            <SiPivotaltracker />
          </span>
          <b>SNAP Automation</b>
          <span className='vl'></span>
          <button className='h-user'>
            <BiUser />
          </button>
          <span style={{ float: 'right', margin: '4px 10px' }}>
            {decodedToken?.email}
          </span>
          {/* <OptionsModal /> */}
        </p>
      </div>
    </div>
  );
}

export default Header;
