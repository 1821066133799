import React, { useState, useEffect } from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import MainBody from './components/MainBody';
import { TabProvider } from './TabProvider';
import Login from './Login';
import './App.scss';
import 'react-toastify/dist/ReactToastify.css';

function App () {
  const [validated, setValidated] = useState(false);

  const checkValidation = () => {
    const esoToken = localStorage.getItem('esoToken');
    if (esoToken) {
      fetch(process.env.REACT_APP_API_ESO_AUTH_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `bearer ${esoToken}`
        },
        body: JSON.stringify({ ping: 'cold start' })
      }).then((res) => {
        return (res.status === 200) ? setValidated(true) : setValidated(false);
      });
    }
  };

  useEffect(() => {
    checkValidation();
  }, []);

  return (
    !validated
      ? <div><Header /><div className='login-container'><Login checkValidation={checkValidation} /></div><Footer /></div>
      : <div className='main'>
        <TabProvider>
          <Header />
          <MainBody />
          <Footer />
        </TabProvider>
      </div>
  );
}

export default App;
