import React from 'react';

function Footer () {
  return (
    <div className='footer'>
      <hr />
      <div className='cont-f'>
        <img
          className='f-acn-logo-btm'
          src='//www.accenture.com/t20180820T081710Z__w__/ph-en/_acnmedia/Accenture/Dev/Redesign/Acc_Logo_Black_Purple_RGB.PNG'
          alt='Accenture Logo'
        ></img>
        <p className='f-mes'>© {new Date().getFullYear()} Accenture. All Rights Reserved.</p>
        <a href='/' className='f-tou'>
          Terms of Use
        </a>
        <a href='/' className='f-ps'>
          Privacy Statement
        </a>
        <a href='/' className='f-cs'>
          Contact Support
        </a>
      </div>
    </div>
  );
}

export default Footer;
