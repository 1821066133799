import React from 'react';
import { GoCloudDownload } from 'react-icons/go';
// import { valueTernary } from 'react-select/dist/declarations/src/utils';
import { useTabUpdateContext } from '../../TabProvider';

export function DownloadBtn ({ title, processId, campaignId, emailTimestamp, step }) {
  function downloadCSV () {
    const appToken = localStorage.getItem('appToken');
    fetch(`https://${process.env.REACT_APP_API_URL}/download?trigger=download&process_id=${processId}&timestamp=${emailTimestamp}&step=${step}`, {
      method: 'GET',
      headers: {
        'content-type': 'text/csv;charset=UTF-8',
        Authorization: `bearer ${appToken}`
      }
    }).then(async (res) => {
      return res.text();
    }).then((res) => {
      console.log('res', res);
      const data = new Blob([res], { type: 'text/csv' });
      const csvURL = window.URL.createObjectURL(data);
      const tempLink = document.createElement('a');
      tempLink.href = csvURL;
      tempLink.setAttribute('download', `${emailTimestamp}-${processId}-${step}.csv`);
      tempLink.click();
    });
  }
  return (
    <div className='dlBtn'>
      <GoCloudDownload
        onClick={() => {
          // toggleTab(title)
          downloadCSV();
        }}
      />
    </div>
  );
}

export function MenuCompletedBtn ({ title, color }) {
  const toggleTab = useTabUpdateContext();
  return (
    <div className={'menuBtn cursorPointer ' + color}>
      <div
        onClick={() => {
          toggleTab(title);
        }}
      >
        details
      </div>
    </div>
  );
}

export function MenuBtn ({ title, color }) {
  const toggleTab = useTabUpdateContext();
  return (
    <div className={'menuBtnRunning cursorPointer ' + color}>
      <div
        onClick={() => {
          toggleTab(title);
        }}
      >
        details
      </div>
    </div>
  );
}
