import React, { useState, useContext } from 'react';

const TabContext = React.createContext();
const TabUpdateContext = React.createContext();

export function useTab () {
  return useContext(TabContext);
}

export function useTabUpdateContext () {
  return useContext(TabUpdateContext);
}

export function TabProvider ({ children }) {
  const [activeTab, setActiveTab] = useState('Step 1');
  function toggleTab (step) {
    setActiveTab(step);
  }
  return (
    <>
      <TabContext.Provider value={activeTab}>
        <TabUpdateContext.Provider value={toggleTab}>{children}</TabUpdateContext.Provider>
      </TabContext.Provider>
    </>
  );
}
