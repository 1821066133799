import React, { useState, useEffect } from 'react';
import Moment from 'react-moment';
import Pagination from '../Helper/Pagination';

const Modal = ({ processList, onClose, show }) => {
  useEffect(() => {
    setprList(processList);
  }, [processList]);
  const [searchTerm, setSearchTerm] = useState('');

  const [prList, setprList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [prListPerPage] = useState(14);

  const indexOfLastPrList = currentPage * prListPerPage;
  const indexOfFirstPrList = indexOfLastPrList - prListPerPage;
  const currentPrList = prList.slice(indexOfFirstPrList, indexOfLastPrList);
  const paginate = (pageNumber) => { return setCurrentPage(pageNumber); };

  function search (rows) {
    const filterHeaders = Object.keys(processList);
    const newTableRows = [];
    if (searchTerm) {
      prList.forEach((element) => {
        newTableRows.push(Object.assign([], Object.values(element)));
      });
    } else {
      rows.forEach((element) => {
        newTableRows.push(Object.assign([], Object.values(element)));
      });
    }

    const newResult = newTableRows.filter((row) => {
      return filterHeaders.some((column5) => {
        if (row[column5]) {
          return row[column5].toString().toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
        }
        return false;
      });
    });

    return (
      <>
        {newResult.map((data, id) => {
          return (
            <tr key={id}>
              <td>
                <Moment format='YYYY-MMM-DD LT'>{data[3]}</Moment>
              </td>
              <td>{data[1]}</td>
              <td>Step {data[4]}</td>
              <td>{data[2]}</td>
              <td>5 Minutes</td>
              <td>
                <a
                  href={`/?idType=process_id&id=${data[0]}&email=${new URLSearchParams(window.location.search).get('email')}`}
                  className='btnViewDetails'
                >
                  View Details
                </a>
              </td>
            </tr>
          );
        })}
      </>
    );
  }

  return (
    <div className={`modal ${show ? 'show' : ''}`} onClick={onClose}>
      <div className='modal-content' onClick={(e) => { return e.stopPropagation(); }}>
        <div className='modal-header'>
          <div className='modal-header'>
            <h3 className='modal-title'>Automation Process List</h3>
            <button className='btnClose close' onClick={onClose}></button>
          </div>
        </div>
        <div className='modal-body'>
          <div className='modal-header-p1'>
            <div className='body-bottom_input-container' style={{ display: 'none' }}>
              <div className='field'>
                <input
                  type='text'
                  placeholder='Enter search value...'
                  value={searchTerm}
                  onChange={(e) => {
                    setSearchTerm(e.target.value);
                    setCurrentPage(1);
                  }}
                />
              </div>
            </div>
          </div>
          <table className='styled-table-btn'>
            <thead>
              <tr>
                <th>Job Starting Time</th>
                <th>Invocation User</th>
                <th>Current Step</th>
                <th>Number Of Companies</th>
                <th>Est. Time Remaining</th>
                <th className='blank'></th>
              </tr>
            </thead>
            <tbody>{search(currentPrList)}</tbody>
          </table>
          {!searchTerm && <Pagination prListPerPage={prListPerPage} totalPrList={prList.length} paginate={paginate} />}
        </div>
        <div className='modal-footer'></div>
      </div>
    </div>
  );
};

export default Modal;
