import React from 'react';
import BodyStatusTable from '../BodyStatusTable';

export function Step8 ({ content }) {
  return (
    <>
      <h2 className='body-bottom-header'>Step 9 - Crawler Execution</h2>
      <h4 className='body-bottom-subheader'>
        Run the required crawlers on all the websites.
      </h4>
      <BodyStatusTable content={content} />
    </>
  );
}
