import React, { useState } from 'react';

export function Step11 ({ dataPreEnrichment }) {
  const [searchTerm, setSearchTerm] = useState('');
  let tableHeaders = [];
  let tableRows = [];
  if (typeof dataPreEnrichment !== 'undefined') {
    if (dataPreEnrichment !== null) {
      dataPreEnrichment = dataPreEnrichment.replace(/\{|\}/g, (m) => {
        const replacements = { '{': '[', '}': ']' };
        return replacements[m];
      });
      dataPreEnrichment = JSON.parse(dataPreEnrichment);
      tableHeaders = dataPreEnrichment[0].map((data, id) => {
        return <th key={id}>{data}</th>;
      });
      delete dataPreEnrichment[0];
      tableRows = dataPreEnrichment;
    }
  }
  function search (rows) {
    const headers = Object.keys(tableHeaders);
    const newResult = tableRows.filter((row) => {
      return headers.some((column) => {
        return row[column].toString().toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
      });
    });
    return (
      <>
        {newResult.map((rowItem, index) => {
          return (
            <tr key={index}>
              {rowItem.map((subColumn, sIndex) => {
                return <td key={sIndex}> {subColumn} </td>;
              })}
            </tr>
          );
        })}
      </>
    );
  }
  return (
    <>
      <h2 className='body-bottom-header'>Step 12 - Arrange Result Output</h2>
      <h4 className='body-bottom-subheader'>Create final output file which is ready to be uploaded.</h4>
      <div className='body-bottom_input-container'>
        {dataPreEnrichment !== undefined && (
          <div className='field'>
            <input
              type='text'
              placeholder='Enter search value...'
              value={searchTerm}
              onChange={(e) => {
                return setSearchTerm(e.target.value);
              }}
            />
          </div>
        )}
      </div>
      <div className='tbl-container'>
        <table className='step-1-table'>
          <thead>
            <tr>{tableHeaders}</tr>
          </thead>
          <tbody>{search(tableRows)}</tbody>
        </table>
      </div>
    </>
  );
}
