import React, { useRef } from 'react';
// import Select from 'react-select';
import { AiFillFileAdd } from 'react-icons/ai';
import { FaCloudUploadAlt } from 'react-icons/fa';
import { IconContext } from 'react-icons';

const FileUpload = ({ changeHandler, isFilePicked, selectedFile, handleSubmission, setEmail }) => {
  const hiddenFileInput = useRef(null);
  const handleClick = event => {
    hiddenFileInput.current.click();
  };

  // let options;

  // const email = new URLSearchParams(window.location.search).get('email');
  // if (email) {
  //   options = [
  //     { value: email, label: email }
  //   ];
  // } else {
  //   // options = [
  //   //   { value: 'jerry.b.lin@accenture.com', label: 'jerry.b.lin@accenture.com' },
  //   //   { value: 'ryan.c.tinoco@accenture.com', label: 'ryan.c.tinoco@accenture.com' },
  //   //   { value: 'saransh.maheshwari@accenture.com', label: 'saransh.maheshwari@accenture.com' },
  //   //   { value: 'somya.e.jain@accenture.com', label: 'somya.e.jain@accenture.com' },
  //   //   { value: 'alice.chen@accenture.com', label: 'alice.chen@accenture.com' }
  //   // ];
  // }

  return (
    <>
      <div className='flexbox-container-upload'>
          <div className="flexbox-item-upload-file">
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={changeHandler}
              style={ { display: 'none' } }
            />
            <h3>Upload A File</h3> &nbsp; &nbsp;
            <button className='btn-md' onClick={handleClick}>
              <IconContext.Provider value={{ className: 'btn_icon_md' }}>
              <div>
                <AiFillFileAdd />
              </div>
            </IconContext.Provider> &nbsp;
              Select
            </button> &nbsp; &nbsp;
            {isFilePicked ? selectedFile.name : null}
          </div>
          {/* <div className="flexbox-item-upload">
            <h3>Select User </h3>
            <Select
              options={options}
              onChange={setEmail}
            />
          </div> */}
          <div className="flexbox-item-upload-submit">
            <button className='btn-md' onClick={handleSubmission}>
              <IconContext.Provider value={{ className: 'btn_icon_md' }}>
                <div>
                  <FaCloudUploadAlt />
                </div>
              </IconContext.Provider> &nbsp;
              Submit
            </button>
          </div>
        </div>
      <div className='modalNewItem-footer'></div>
    </>
  );
};

export default FileUpload;
