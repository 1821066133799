import React, { useState } from 'react';
import FileUpload from './UploadModalContent/FileUpload';
import UploadedSuccessfully from './UploadModalContent/UploadedSuccessfully';
import { ToastContainer, toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import { useJwt } from 'react-jwt';

const NewAutomationModal = ({ onClose, show }) => {
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsSelected] = useState(false);
  const [isUploadedSuccessfully, setIsUploadedSuccessfully] = useState(false);
  const [uploadProcessId, setUploadProcessId] = useState('');
  const { decodedToken } = useJwt(localStorage.getItem('appToken'));
  const closeModal = () => {
    console.log('closeModal inside');
    if (uploadProcessId === '') return onClose();
    setTimeout(() => {
      window.location = `/?idType=process_id&id=${uploadProcessId}&email=${new URLSearchParams(window.location.search).get('email')}`;
    }, 1500);
  };

  const changeHandler = (event) => {
    if (typeof event.target.files[0] === 'undefined') return toast.warn('Please select a file');
    if (event.target.files[0].type === 'application/vnd.ms-excel' || event.target.files[0].type === 'text/csv' || event.target.files[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      setUploadProcessId(uuidv4());
      setSelectedFile(event.target.files[0]);
      setIsSelected(true);
      console.log('event file', event.target.files[0]);
    } else {
      toast.warn('Only CSV formats are allowed');
    }
  };
  const handleSubmission = () => {
    console.log('email', decodedToken?.email);
    if (!selectedFile) return toast.warn('Please select a file');
    console.log('uploadProcessId', uploadProcessId);
    console.log('file', selectedFile);
    const uploadToS3 = new Promise((resolve, reject) => {
      if (
        (selectedFile.type === 'application/vnd.ms-excel' || selectedFile.type === 'text/csv' || selectedFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet')
      ) {
        console.log('passed', uploadProcessId);
        console.log('data', selectedFile.value);
        const requestOptions = {
          method: 'POST',
          headers: {
            Authorization: 'bearer ' + localStorage.getItem('appToken'),
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          },
          body: selectedFile
        };
        fetch(
          `https://${process.env.REACT_APP_API_URL}/upload?trigger=upload-test&process_id=${uploadProcessId}&email=${decodedToken?.email}`,
          requestOptions
        )
          .then((response) => {
            console.log('passed 2');
            setIsUploadedSuccessfully(true);
            setSelectedFile();
            setIsSelected(false);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error);
            setUploadProcessId('');
            return console.log('error-api', error);
          });
      }
    });
    toast.promise(uploadToS3, {
      pending: `Uploading ${selectedFile.name}`,
      success: 'Uploaded Successfully',
      error: 'Error uploading the file. Please try again or contact Administrator'
    });
  };

  return (
    <div className={`modalNewItem ${show ? 'show' : ''}`} onClick={closeModal}>
      <ToastContainer />
      <div
        className='modalNewItem-content'
        onClick={(e) => {
          return e.stopPropagation();
        }}
      >
        <div className='modalNewItem-header'>
          <div className='modalNewItem-header'>
            <h3 className='modalNewItem-title'>Create New Automation Job</h3>
            <button className='btnClose close' onClick={closeModal}></button>
          </div>
        </div>
        {!isUploadedSuccessfully
          ? (
          <FileUpload
            changeHandler={changeHandler}
            isFilePicked={isFilePicked}
            selectedFile={selectedFile}
            handleSubmission={handleSubmission}
          />
            )
          : (
          <UploadedSuccessfully uploadProcessId={uploadProcessId} />
            )}
      </div>
    </div>
  );
};

export default NewAutomationModal;
