import React from 'react';
import { IoIosCloudDone } from 'react-icons/io';
import { IconContext } from 'react-icons';

const UploadedSuccessfully = ({ uploadProcessId }) => {
  return (
    <>
      <div className='flexbox-container'>
        <div className='flexbox-item flexbox-item-icon-placeholder'>
          <IconContext.Provider value={{ color: '#9718e8', className: 'flexbox-item-icon' }}>
            <div>
              <IoIosCloudDone />
            </div>
          </IconContext.Provider>
        </div>
        <div className='flexbox-item flexbox-item-text'>
          <h2 className='flexbox-item-h2'>Your automation process has started</h2>
          <h2 className='flexbox-item-h2'>Details for the job will be sent to your email</h2>
          <h3 className='flexbox-item-h3'>Process ID: { uploadProcessId }</h3>
        </div>
      </div>
      <div className='modalNewItem-footer'></div>
    </>
  );
};

export default UploadedSuccessfully;
