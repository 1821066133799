/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
// import { usePageVisibility } from 'react-page-visibility';
import BodyProcessList from './BodyProcessList';
import BodyStatusSteps from './BodyStatusSteps';
import { useTab } from '../TabProvider';
import { Step1 } from './Tabs/FormatData';
import { Step2 } from './Tabs/CleanData';
import { Step3 } from './Tabs/IdentifyDomain';
import { Step4 } from './Tabs/FetchPrivate';
import { Step5 } from './Tabs/FetchPublic';
import { Step6 } from './Tabs/FuzzyMatching';
import { Step7 } from './Tabs/CleanDomain';
import { Step8 } from './Tabs/RunCrawler';
import { Step9 } from './Tabs/CreateReport';
import { Step10 } from './Tabs/CreateScoring';
import { Step11 } from './Tabs/ResultOutput';
import { Step12 } from './Tabs/Tracker';
import { Step13 } from './Tabs/CompanyName';

function MainBody () {
  const [AutomationStatusResult, setAutomationStatusResult] = useState({});
  const [processList, setprocessList] = useState([]);
  const [NewEta, setNewEta] = useState(0);
  const [IsRunning, setIsRunning] = useState(false);
  const [ArrEstimatedSecondsPerStep, setArrEstimatedSecondsPerStep] = useState([0, 17, 316, 352, 913, 921, 930]);
  const urlParams = new URLSearchParams(window.location.search);
  const activeTab = useTab();
  // const isVisible = usePageVisibility();

  function getStep ({ step_1, step_2, step_3, step_4, step_5, step_6, step_7 }) {
    if (step_7 === 'success') return 7;
    if (step_6 === 'success') return 6;
    if (step_5 === 'success') return 5;
    if (step_4 === 'success') return 4;
    if (step_3 === 'success') return 3;
    if (step_2 === 'success') return 2;
    if (step_1 === 'success') return 1;
  }
  function getAutomationStatus () {
    axios
      .get(
        `https://${process.env.REACT_APP_API_URL}/automation-status?${urlParams.get('idType')}=${urlParams.get('id')}`, {
          headers: {
            Authorization: 'bearer ' + localStorage.getItem('appToken')
          }
        }
      )
      .then((response) => {
        // console.log(response.data[0]);
        try {
          // response.data[0].job_list = JSON.parse(response.data[0].job_list);
          response.data[0].step = getStep(response.data[0]);
          setAutomationStatusResult(response.data[0]);
          if (!IsRunning) configureEta(response.data[0].no_companies, response.data[0].step);
          // console.log(step);
        } catch (err) {
          console.log(err);
        }
      })
      .catch((err) => {
        console.log('Internal Server Error: Please try again', err);
        setAutomationStatusResult({
          process_id: 'No Results Found'
        });
      })
      .finally(() => {
        // add a flag that the response has been finished
      });
  }
  const getProcessList = () => {
    axios
      .get(
        `https://${process.env.REACT_APP_API_URL}/process-list?trigger=process-list`, {
          headers: {
            Authorization: 'bearer ' + localStorage.getItem('appToken')
          }
        }
      )
      .then((response) => {
        if (!response.data) {
          setprocessList('No Results Found');
        } else {
          if (!response.data) return null;
          setprocessList(response.data);
        }
      });
  };
  const configureEta = (inputCompanyCount, inputCurrentStep) => {
    if (inputCurrentStep === 0) return null;
    if (typeof inputCurrentStep === 'undefined') return null;
    const multiplier = inputCompanyCount / 100;
    const baseETA = 930 * multiplier;
    setArrEstimatedSecondsPerStep(
      ArrEstimatedSecondsPerStep.map((step) => {
        return Math.round(step * multiplier);
      })
    );
    const computedEta = parseInt(baseETA) - parseInt(ArrEstimatedSecondsPerStep[parseInt(inputCurrentStep - 1)]);
    setNewEta(computedEta);
    setIsRunning(true);
  };

  useEffect(() => {
    if (urlParams.get('id') === null) return null;
    getProcessList();
    getAutomationStatus();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (urlParams.get('id') === null) return null;
      getProcessList();
      getAutomationStatus();
    }, 10000);
  }, [AutomationStatusResult]);

  return (
    <div className='main-body'>
      <div className='body-top'>
        <BodyProcessList
          // key={(isVisible && NewEta)}
          key={AutomationStatusResult.step}
          content={AutomationStatusResult}
          processList={processList}
          getProcessList={getProcessList}
          eta={NewEta}
          isRunning={IsRunning}
        />
      </div>
      <div className='body-middle'>
        <BodyStatusSteps content={AutomationStatusResult} arrEstimatedSecondsPerStep={ArrEstimatedSecondsPerStep} newEta={NewEta}/>
      </div>
      <hr></hr>
      <div className='body-bottom'>
        {activeTab === 'Step 1' && <Step1 dataPreEnrichment={AutomationStatusResult.import_data} />}
        {activeTab === 'Step 2' && <Step2 dataPreEnrichment={AutomationStatusResult.clean_import_data}/>}
        {activeTab === 'Step 3' && <Step3 dataPreEnrichment={AutomationStatusResult.identify_domain_data}/>}
        {activeTab === 'Step 4' && <Step4 dataPreEnrichment={AutomationStatusResult.fetch_private_domain_data}/>}
        {activeTab === 'Step 5' && <Step13 dataPreEnrichment={AutomationStatusResult.company_name_dq_data} />}
        {activeTab === 'Step 6' && <Step5 dataPreEnrichment={AutomationStatusResult.fetch_public_domain_data} />}
        {activeTab === 'Step 7' && <Step6 dataPreEnrichment={AutomationStatusResult.fuzzy_match_domain_data}/>}
        {activeTab === 'Step 8' && <Step7 dataPreEnrichment={AutomationStatusResult.clean_domain_data} />}
        {activeTab === 'Step 9' && <Step8 content={AutomationStatusResult} />}
        {activeTab === 'Step 10' && <Step9 dataPreEnrichment={AutomationStatusResult.create_report_data} />}
        {activeTab === 'Step 11' && <Step10 dataPreEnrichment={AutomationStatusResult.create_scoring_data} />}
        {activeTab === 'Step 12' && <Step11 dataPreEnrichment={AutomationStatusResult.final_output_data} />}
        {activeTab === 'Step 13' && <Step12 dataPreEnrichment={AutomationStatusResult.tracker} />}
      </div>
    </div>
  );
}

export default MainBody;
