import React from 'react';
// import Moment from 'react-moment';s

function BodyStatusTable ({ content }) {
  // let data = [];
  // let crawlerName;
  // let jobId;
  // let status;
  // let progress;
  // let displayName;
  // let startTime = [];
  // let endTime = [];
  // if (typeof content === 'object' && typeof content !== 'undefined' && content !== null) {
  //   // console.log(content);
  //   data = content.map((data, id) => {
  //     return data;
  //   });
  //   crawlerName = data.map((data, id) => {
  //     data.crawler_name = data.crawler_name.replace('_', ' ');
  //     data.crawler_name = data.crawler_name.replace(/(^\w|\s\w)/g, (m) => {
  //       return m.toUpperCase();
  //     });
  //     return <th key={id}>{data.crawler_name}</th>;
  //   });

  //   displayName = data.map((data, id) => {
  //     if (data.crawler_display_name != null) {
  //       return <td key={id}>{data.crawler_display_name}</td>;
  //     } else {
  //       data.crawler_name = data.crawler_name.replace('_', ' ');
  //       data.crawler_name = data.crawler_name.replace(/(^\w|\s\w)/g, (m) => {
  //         return m.toUpperCase();
  //       });
  //       return <td key={id}>{data.crawler_name}</td>;
  //     }
  //   });

  //   jobId = data.map((data, id) => {
  //     return (
  //       <td key={id}>
  //         <b>{data.job_id}</b>
  //       </td>
  //     );
  //   });

  //   status = data.map((data, id) => {
  //     return <td key={id}>{data.status}</td>;
  //   });

  //   progress = data.map((data, id) => {
  //     return (
  //       <td key={id}>
  //         <b>{data.progress}%</b>
  //       </td>
  //     );
  //   });

  //   startTime = data.map((data, id) => {
  //     if (data.start_time != null) {
  //       return (
  //         <td key={id}>
  //           <Moment format='YYYY-MMM-DD LTS'>{data.start_time}</Moment>
  //         </td>
  //       );
  //     }
  //     return <td key = {id}></td>;
  //   });

  //   endTime = data.map((data, id) => {
  //     if (data.end_time != null) {
  //       return (
  //         <td key={id}>
  //           <Moment format='YYYY-MMM-DD LTS'>{data.end_time}</Moment>
  //         </td>
  //       );
  //     }
  //     return <td key = {id}></td>;
  //   });
  // }

  return (
    <div className='mb-bottom'>
      <table className='styled-table'>
        <thead>
          <tr>
            <th>
              <b>Crawlers:</b>
            </th>
            {/* {crawlerName || null} */}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <b>All Attribute Crawler: {content.all_attribute}</b>
            </td>
            {/* {displayName || null} */}
          </tr>
          <tr>
            <td>
              <b>Chatbot API Crawler {content.chatbot_api}</b>
            </td>
            {/* {jobId || null} */}
          </tr>
          <tr>
            <td>
              <b>Chatbot Keywords Crawler: {content.chatbot_keywords}</b>
            </td>
            {/* {status || null} */}
          </tr>
          <tr>
            <td>
              <b>Tiktok Crawler: {content.tiktok}</b>
            </td>
            {/* {progress || null} */}
          </tr>
          <tr>
            <td>
              <b>Covid Crawler: {content.covid}</b>
            </td>
            {/* {startTime || null} */}
          </tr>
          <tr>
            <td>
              <b>Images Crawler: {content.hqi}</b>
            </td>
            {/* {endTime || null} */}
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default BodyStatusTable;
