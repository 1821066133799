import React from 'react';
import Moment from 'moment';
import { FiCheckCircle } from 'react-icons/fi';
import { IconContext } from 'react-icons';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { BsExclamationCircle } from 'react-icons/bs';
import { DownloadBtn, MenuCompletedBtn, MenuBtn } from './CardButtons';

function Cards ({ title, description, status, date, processId, campaignId, step, emailTimestamp, estimatedTime }) {
  // const [ActiveTabs, setActiveTabs] = useState('Step 1');
  let menus = '';
  if (title === 'Step 13' || title === 'Step 7') {
    menus = (
      <IconContext.Provider value={{ color: 'white', className: 'check' }}>
        <div className='card-buttons'>
          <DownloadBtn title={title} processId={processId} campaignId={campaignId} emailTimestamp={emailTimestamp} step={step} />
          <MenuCompletedBtn title={title} color='white' />
        </div>
      </IconContext.Provider>
    );
  } else {
    menus = (
      <IconContext.Provider value={{ color: 'white', className: 'check' }}>
        <div className='card-buttons'>
          <DownloadBtn title={title} processId={processId} campaignId={campaignId} emailTimestamp={emailTimestamp} step={step} />
          <MenuBtn title={title} color='white' />
        </div>
      </IconContext.Provider>
    );
  }
  if (status === 'COMPLETED') {
    return (
      <div className='card card-ok'>
        <span className='card__exit'>
          <IconContext.Provider value={{ color: 'white', className: 'check' }}>
            <div>
              <FiCheckCircle />
            </div>
          </IconContext.Provider>
        </span>
        <h2 className='card__title'>
          <small>
            {title}
            <br />
            <br />
          </small>
          {description}
        </h2>
        <span className='card__apply_white'>
          <span style={{ color: '#fdf', fontSize: '11px' }}>
            {' '}
            Completed at:
            <br />
            <span>{Moment(date).format('M/D/YY hh:mm:ssa')}</span>
          </span>
          <br />
          <span style={{ color: '#fdf', fontSize: '11px' }}>
            {' '}
            est: {estimatedTime}
            <br />
          </span>
        </span>
        {menus}
      </div>
    );
  } else if (status === 'STARTING') {
    return (
      <div className='card card-ok'>
        <span className='card__exit'>
          <IconContext.Provider value={{ color: 'white', className: 'loader' }}>
            <div>
              <AiOutlineLoading3Quarters />
            </div>
          </IconContext.Provider>
        </span>
        <h2 className='card__title'>
          <small>
            {title}
            <br />
            <br />
          </small>
          {description}
        </h2>
        <span className='card__apply_white'>
          <span style={{ color: 'white' }}>
            <span>Running</span>
          </span>
          <br />
          <span className='card__apply_white'>
            <span style={{ color: '#fdf', fontSize: '11px' }}>
              {' '}
              est: {estimatedTime}
              <br />
            </span>
          </span>
        </span>
        {menus}
      </div>
    );
  } else if (status === 'failed') {
    return (
      <div className='card card-failed'>
        <span className='card__exit'>
          <IconContext.Provider value={{ color: 'white', className: 'exclamation' }}>
            <div>
              <BsExclamationCircle />
            </div>
          </IconContext.Provider>
        </span>
        {/* <h2 className='card__title_black'>{title}</h2> */}
        {/* <span className='card__apply_white'>
          <span>
            <span>Failed</span> <i className='fas fa-arrow-right'></i>
          </span>
        </span> */}
        <h2 className='card__title'>
          <small>
            {title}
            <br />
            <br />
          </small>
          {description}
        </h2>
        <span className='card__apply_white'>
          <span style={{ color: 'white' }}>
            <span>Failed</span>
          </span>
          <br />
          <span style={{ color: '#fdf', fontSize: '11px' }}>
            {' '}
            <span>{Moment(date).format('M/D/YY hh:mm:ssa')}</span>
          </span>
        </span>
        <IconContext.Provider value={{ color: 'white', className: 'check' }}>
        <div className='card-buttons2'>
          <MenuBtn title={title} color='white' />
        </div>
      </IconContext.Provider>
      </div>
    );
  // } else if (typeof status === 'undefined') {
  } else if (status == null) {
    return (
      <div className='card card-pending'>
        <span className='card__exit'></span>
        <h2 className='card__title_black'>
          <small>
            {title}
            <br />
            <br />
          </small>
          {description}
        </h2>
        {/* <span className='card__apply_white'>
        </span> */}
      </div>
    );
  } else {
    return (
      <div className='card card-pending'>
        <span className='card__exit'>
          <IconContext.Provider value={{ color: 'black', className: 'loader' }}>
            <div>
              <AiOutlineLoading3Quarters />
            </div>
          </IconContext.Provider>
        </span>
        <h2 className='card__title_black'>
          <small>
            {title}
            <br />
            <br />
          </small>
          {description}
        </h2>
        <span className='card__apply_white'>
          <span>Pending</span>
          <br />
          <span className='card__apply_white'>
            <span style={{ color: '#000', fontSize: '11px' }}>
              {' '}
              est: {estimatedTime}
              <br />
            </span>
          </span>
        </span>
        <IconContext.Provider value={{ color: 'black', className: 'check' }}>
          <div className='card-buttons2'>
            <MenuBtn title={title} color='black' />
          </div>
        </IconContext.Provider>
      </div>
    );
  }
}

export default Cards;
